<template>
  <div class="home">
    <!-- 公告 -->
    <div class="notice-box">
      <!-- 轮播 -->
      <div class="banner">
        <carousel
          ref="carousel"
          :carouselHeight="'314px'"
          :carouselInterval="4000"
          :arr="imgArr"
          @carouselChange="carouselChange"
        ></carousel>
      </div>
      <div class="banner-text" @click="carouselClick">
        <div class="b-title">{{ imgObj ? imgObj.title : "" }}</div>
        <div class="b-content">
          {{ imgObj ? imgObj.description : "" }}
        </div>
      </div>
      <!-- 滚动通知列表 -->
      <div class="home-notice">
        <roll-notice :arr="rollList"></roll-notice>
      </div>
    </div>
    <!-- 动态-->
    <div class="dynamic-box">
      <div class="dynamic-item">
        <div class="title">
          <div class="laboratory-icon"></div>
          <div class="name">{{ homeBlock[0].title }}</div>
          <div class="more" @click="toMore(homeBlock[0].title)">更多...</div>
        </div>
        <dynamic-list :arr="sysList"></dynamic-list>
      </div>
      <div
        class="dynamic-banner"
        :style="`background-image: url(${homeBlock[0].icon})`"
        @click="toMore(homeBlock[0].title)"
        @mouseover="onMouseover(0)"
        @mouseout="onMouseout(0)"
      >
        <div class="mask"></div>
        <h1 class="la-title animate__animated">
          {{ homeBlock[0].title }}
        </h1>
      </div>
    </div>
    <div class="dynamic-box">
      <div
        class="dynamic-banner"
        :style="`background-image: url(${homeBlock[1].icon}); float:left`"
        @click="toMore(homeBlock[1].title)"
        @mouseover="onMouseover(1)"
        @mouseout="onMouseout(1)"
      >
        <div class="mask"></div>
        <h1 class="la-title animate__animated">
          {{ homeBlock[1].title }}
        </h1>
      </div>
      <div class="dynamic-item" style="margin-right: 0; margin-left: 45px; float: right">
        <div class="title">
          <div class="industry-icon"></div>
          <div class="name">{{ homeBlock[1].title }}</div>
          <div class="more" @click="toMore(homeBlock[1].title)">更多...</div>
        </div>
        <dynamic-list :arr="hyList"></dynamic-list>
      </div>
    </div>
    <div class="dynamic-box">
      <div class="dynamic-item">
        <div class="title">
          <div class="trade-icon"></div>
          <div class="name">{{ homeBlock[2].title }}</div>
          <div class="more" @click="toMore(homeBlock[2].title)">更多...</div>
        </div>
        <dynamic-list :arr="cyList"></dynamic-list>
      </div>
      <div
        class="dynamic-banner"
        :style="`background-image: url(${homeBlock[2].icon}) `"
        @click="toMore(homeBlock[2].title)"
        @mouseover="onMouseover(2)"
        @mouseout="onMouseout(2)"
      >
        <div class="mask"></div>
        <h1 class="la-title animate__animated">
          {{ homeBlock[2].title }}
        </h1>
      </div>
    </div>
    <!-- 我的横幅 -->
    <div
      v-if="bannerItem"
      class="banner-box"
      :style="`background-image: url(${bannerItem ? bannerItem.advImg : ''});`"
      @click="toDetails(0)"
    ></div>
    <!-- 合作伙伴 -->
    <div class="dynamic-box">
      <div class="dynamic-item tile-width">
        <div class="title">
          <div class="name">合作伙伴</div>
        </div>
        <div
          class="hzhb"
          v-if="cooperItem"
          :style="`background-image: url(${cooperItem ? cooperItem.pantnerImg : ''});`"
          @click="toDetails(1)"
        ></div>
        <div class="connect-list">
          <ul>
            <li v-for="(item, index) in cooperationArr" :key="index">
              <a :href="item.url" target="_blank">{{ item.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 友情连接 -->
    <div class="dynamic-box">
      <div class="dynamic-item tile-width">
        <div class="title">
          <div class="name">友情链接</div>
          <!-- <div class="more" @click="toMore(0)">更多...</div> -->
        </div>
        <div class="connect-list">
          <ul>
            <li v-for="(item, index) in linkArr" :key="index">
              <a :href="item.url" target="_blank">{{ item.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import rollNotice from "./../../components/roll-notice"; // 滚动通知列表
import carousel from "./../../components/carousel";
import dynamicList from "./components/dynamic-list";
import schemeList from "./components/scheme-list";
import { getTreeItem } from "@/utils/index";
export default {
  name: "Home",
  components: {
    rollNotice,
    carousel,
    dynamicList,
    // schemeList,
  },
  data() {
    return {
      // 轮播图
      imgArr: [],
      imgObj: null,
      // 友情链接
      linkArr: [],
      // 合作伙伴
      cooperationArr: [],
      // 金融产业动态
      cyList: [],
      // 行业动态
      hyList: [],
      // 解决方案
      jjfaList: [],
      // 实验室动态
      sysList: [],
      // 左侧滚动数据
      rollList: [],
      bannerItem: null,
      cooperItem: null,
      show: true,
      // 实验室、金融、信息
      homeBlock: [
        {
          title: "实验室动态",
          icon: require("./../../assets/images/laboratory-banner.png"),
          isShow: false,
          isShake: false,
          isRoll: true,
        },
        {
          title: "金融行业动态",
          icon: require("./../../assets/images/finance-banner.png"),
          isShow: false,
          isShake: false,
          isRoll: true,
        },
        {
          title: "信息产业动态",
          icon: require("./../../assets/images/information-banner.png"),
          isShow: false,
          isShake: false,
          isRoll: true,
        },
      ],
      oneText: false,
      twoText: false,
      threeText: false,
      scrollTop: 0, // 滚动条高度
    };
  },
  created() {
    this.getSliderList(); // 获取轮播图
    this.getLinkList(); // 友情链接
    this.getCooperationArr(); // 合作伙伴
    this.getTypeList();
    this.getNoticeList();
    this.getAdvList();
    this.getPanList();
  },
  mounted() {
    window.addEventListener("scroll", this.btn_pos); // 监听滚动条
    this.btn_pos();
  },
  methods: {
    onMouseover(index) {
      this.homeBlock[index].isShake = true;
      this.homeBlock[index].isRoll = false;
    },
    onMouseout(index) {
      this.homeBlock[index].isShake = false;
    },
    btn_pos: function () {
      //滚动条的高度
      this.scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      if (this.scrollTop >= 0) {
        this.homeBlock[0].isShow = true;
      }
      if (this.scrollTop >= 150) {
        this.homeBlock[1].isShow = true;
      }
      if (this.scrollTop >= 420) {
        this.homeBlock[2].isShow = true;
      }
    },
    carouselChange(index) {
      this.imgObj = this.imgArr[index];
    },
    carouselClick() {
      this.$refs.carousel.toDetails(this.imgObj);
    },
    toMore(name) {
      let myMenus = JSON.parse(sessionStorage.getItem("menus"));
      let item = getTreeItem(myMenus, name, "name");
      let param = {
        pid: item.pid,
        sid: item.id,
      };
      this.$router.push({ path: "/second", query: param });
    },
    async getSliderList() {
      let res = await this.$api.home.sliderList();
      if (res.status == 200) {
        this.imgArr = res.data.records;
        this.imgObj = this.imgArr[0]; // 默认第一个
      }
    },
    async getLinkList() {
      let res = await this.$api.home.linkList();
      if (res.status == 200) {
        this.linkArr = res.data;
      }
    },
    async getCooperationArr() {
      let res = await this.$api.home.cooperationList();
      if (res.status == 200) {
        this.cooperationArr = res.data;
      }
    },
    async getTypeList() {
      let res = await this.$api.home.typeList();
      if (res.status == 200) {
        for (let key in res.data) {
          if (res.data[key]) {
            for (let i = 0; i < res.data[key].length; i++) {
              res.data[key][i]["createTime"] = res.data[key][i]["createTime"].substr(
                0,
                10
              );
              // if (key == "jjfaList") {
              //   if (res.data[key][i].description.length >= 40) {
              //     res.data[key][i].handleDescription =
              //       res.data[key][i].description.substr(0, 40) + "...";
              //   } else {
              //     res.data[key][i].handleDescription = res.data[key][i].description;
              //   }
              //   if (i + 1 <= 3) {
              //     this.jjfaList.push(res.data[key][i]);
              //   }
              // }
            }
          }
        }
        this.cyList = res.data.cyList;
        this.hyList = res.data.hyList;
        // this.jjfaList = res.data.jjfaList;
        this.sysList = res.data.sysList;
      }
    },
    async getNoticeList() {
      let _this = this;
      let res = await this.$api.home.noticeList();
      if (res.status == 200) {
        for (let i = 0; i < res.data.records.length; i++) {
          res.data.records[i]["createTime"] = res.data.records[i]["createTime"].substr(
            5,
            6
          );
        }
        _this.rollList = res.data.records;
      }
    },
    async getAdvList() {
      let res = await this.$api.second.getAdvList();
      if (res.status == 200) {
        this.bannerItem = res.data.records.length > 0 ? res.data.records[0] : null;
      }
    },
    async getPanList() {
      let res = await this.$api.second.getPanList();
      console.log("获取合作伙伴 --- ", res)
      if (res.status == 200) {
        this.cooperItem = res.data.records.length > 0 ? res.data.records[0] : null;
      }
    },
    toDetails(code) {
      let item = null;
      if (code == 0) {
        item = this.bannerItem;
      } else {
        item = this.cooperItem;
      }
      if (item != null) {
        sessionStorage.setItem("classification", "rightNotice_3");
        sessionStorage.setItem("articleId", item.id);
        this.$router.push({ path: "/third" });
      }
      // sessionStorage.setItem("classification", item.bizCategory.id);
    },
  },
};
</script>
<style lang="scss">
@import "./../../assets/style/public.scss";
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active 用于 2.1.8 以下版本 */ {
  transform: translateX(10px);
  opacity: 0;
}
.home {
  width: 1080px;
  margin: 0 auto;
  height: 100%;
  .notice-box {
    @extend %clearfix;
    .banner {
      // width: 680px;
      // width: 455px;
      width: 520px;
      height: 314px;
      float: left;
      cursor: pointer;
      img {
        width: 680px;
        height: 314px;
        object-fit: cover;
      }
    }
    .banner-text {
      // width: 205px;
      cursor: pointer;
      width: 140px;
      height: 294px;
      background: #c01515;
      float: left;
      padding: 10px;
      .b-title {
        color: #eeecec;
        font-size: 12px;
        font-weight: bold;
        line-height: 30px;
      }
      .b-content {
        color: #eeecec;
        font-size: 12px;
        text-indent: 2em;
        margin-top: 10px;
        line-height: 30px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
      }
    }
    .home-notice {
      float: left;
      margin-left: 35px;
    }
  }
  .dynamic-box {
    margin-top: 30px;
    // 动画

    @extend %clearfix;
    .tile-width {
      width: 100% !important;
      height: auto !important;
    }

    .dynamic-banner {
      float: right;
      line-height: 270px;
      border-radius: 2px;
      width: 640px;
      height: 270px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      cursor: pointer;
      position: relative;
      .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #909399;
        opacity: 0.5;
        z-index: 999;
        display: none;
      }
      .la-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 40px;
        color: #ffffff;
        text-align: center;
        z-index: 1000;
      }
      &:hover {
        .mask {
          display: block;
        }
      }
    }
    .dynamic-item {
      // width: 325px;
      // width: 350px;
      width: 390px;
      height: 270px;
      overflow: hidden;
      float: left;
      margin-right: 45px;
      &:last-child {
        margin-right: 0;
      }
      .title {
        padding-bottom: 10px;
        border-bottom: 1px solid #dbdcdb;
        @extend %clearfix;
        .laboratory-icon {
          @include bgImg("../../assets/images/laboratory.png", 20px, 20px);
          float: left;
          margin-top: 4px;
        }
        .industry-icon {
          @include bgImg("../../assets/images/industry.png", 20px, 20px);
          float: left;
          margin-top: 4px;
        }
        .trade-icon {
          @include bgImg("../../assets/images/trade.png", 20px, 20px);
          float: left;
          margin-top: 4px;
        }
        .scheme-icon {
          @include bgImg("../../assets/images/scheme.png", 16px, 20px);
          float: left;
          margin-top: 4px;
        }
        .name {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #c01515;
          line-height: 28px;
          float: left;
          margin-left: 9px;
        }
        .more {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #c01515;
          line-height: 17px;
          float: right;
          margin-top: 7px;
          cursor: pointer;
        }
      }
    }
    .connect-list {
      margin-top: 20px;
      // border-bottom: 1px solid #dbdcdb;
      ul {
        li {
          list-style-type: none;
          display: inline-block;
          width: 25%;
          margin-bottom: 20px;
          a {
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #222222;
            line-height: 17px;
          }
        }
      }
    }
    .hzhb {
      @include bgImg("../../assets/images/WechatIMG3274.jpeg", 100%, 270px);
      margin-bottom: 10px;
      margin-top: 5px;
    }
  }
  .banner-box {
    width: 1080px;
    height: 97px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 30px;
    margin-bottom: 30px;
    cursor: pointer;
  }
}
</style>
