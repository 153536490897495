<template>
  <el-carousel :height="carouselHeight" :interval="carouselInterval" @change="carouselChange">
    <el-carousel-item v-for="(item, index) in arr" :key="index">
      <img :src="item.sliderImg" alt="加载失败请联系管理员" style="width:100%" @click="toDetails(item)" />
    </el-carousel-item>
  </el-carousel>
</template>
<script>
import { carousel, carouselItem } from "element-ui";
export default {
  components: {
    "el-carousel": carousel,
    "el-carousel-item": carouselItem,
  },
  data() {
    return {};
  },
  props: {
    carouselHeight: String,
    carouselInterval: Number,
    arr: Array,
  },
  methods: {
    toDetails(item) {
      sessionStorage.setItem("classification", "rightNotice_2");
      sessionStorage.setItem("articleId", item.id);
      this.$router.push({ path: "/third" });
    },
    carouselChange(index) {
      this.$emit("carouselChange", index)
    }
  },
};
</script>
<style lang="stylus"></style>