<template>
  <div class="scheme-list">
    <div
      :class="{
        'scheme-item': true,
        'border-box': arr.length > 3 && index < 3,
      }"
      v-for="(item, index) in arr"
      :key="index"
      @click="toPage(item)"
    >
      <ul>
        <li>
          <a href="javascript:void(0)">
            {{ item.title }}
          </a>
        </li>
      </ul>
      <div class="content">
        <p class="brief">
          {{ item.handleDescription }}
        </p>
        <!-- <a href="javascript:void(0)" @click="toPage(item)">详细方案>></a> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    arr: Array,
  },
  methods: {
    toPage(item) {
      // sessionStorage.setItem("classification", item.bizCategory.name);
      sessionStorage.setItem("classification", item.bizCategory.id)
      sessionStorage.setItem("articleId", item.id);
      this.$router.push({ path: "/third" });
    },
  },
};
</script>
<style lang="scss">
@import "./../../../assets/style/public.scss";
.scheme-list {
  @extend %clearfix;
  .border-box {
    border-bottom: 1px dashed #979797;
  }
  .scheme-item {
    float: left;
    padding: 15px 89px 20px 0;
    &:nth-child(3) {
      padding-right: 0;
    }
    &:nth-child(6) {
      padding-right: 0;
    }
    ul {
      margin-left: 18px;
      li {
        list-style-type: disc;
        color: #c01515;
        a {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #c01515;
          line-height: 17px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 216px;
          display: inline-block;
          line-height: 11px;
        }
      }
    }
    .content {
      width: 300px;
      height: 48px;
      margin-top: 10px;
      position: relative;
      .brief {
        width: 250px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        line-height: 26px;
        text-indent: 2em;
        overflow: hidden;
        text-overflow: ellipsis;
        // white-space: nowrap;
        display: -webkit-box;
        -webkit-line-clamp: 2; // 控制多行的行数
        -webkit-box-orient: vertical;
      }
      a {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #c01515;
        line-height: 17px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
</style>