<template>
  <div class="dynamic-list">
    <ul>
      <li v-for="(item, index) in arr" :key="index">
        <a href="javascript:void(0)" @click="toPage(item)">{{ item.title }}</a>
        <span class="time">{{ item.createTime }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    arr: Array,
  },
  methods: {
    toPage(item) {
      // sessionStorage.setItem("classification", item.bizCategory.name)
      sessionStorage.setItem("classification", item.bizCategory.id)
      sessionStorage.setItem("articleId", item.id)
      this.$router.push({ path: "/third"});
    },
  },
};
</script>
<style lang="scss">
.dynamic-list {
  margin-top: 15px;
  ul {
    margin-left: 18px;
    li {
      list-style-type: disc;
      margin-bottom: 15px;
      a {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        line-height: 17px;
        width: 280px;
        float: left;
        margin-top: 3px;
        &:hover {
          color: #C01515;
        }
      }
      .time {
        float: right;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #888888;
        line-height: 17px;
        margin-top: 3px;
      }
    }
  }
}
</style>