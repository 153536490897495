<!-- 通知公告组件 -->
<template>
  <div class="roll-notice">
    <div class="title">通知公告</div>
    <div id="news" class="content">
      <ul ref="rolUll" @mouseout="onStart" @mouseover="onStop">
        <li v-for="(item, index) in arr" :key="index">
          <a href="javascript:void(0)" @click="toPage(item)">
            {{ item.title }}
          </a>
          <span class="time">{{ item.createTime }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      num: 50,
      syTimer: null,
      lisTotalHeight: 0,
      resetHeight: 0,
      mapTop: 0,
    };
  },
  props: {
    arr: Array,
  },
  watch: {
    arr: {
      handler: function (val) {
        this.$nextTick(function () {
          this.init();
        });
      },
    },
  },
  beforeDestroy() {
    this.onStop();
  },
  methods: {
    init() {
      if (this.arr.length > 5 && this.$refs.rolUll) {
        let _this = this;
        let lis = this.$refs.rolUll.getElementsByTagName("li");
        for (var i = 0; i < lis.length; i++) {
          _this.lisTotalHeight += lis[i].offsetHeight;
        }
        for (var j = 0; j < lis.length - 2; j++) {
          _this.resetHeight += lis[j].offsetHeight;
        }
        this.$refs.rolUll.style.height = _this.lisTotalHeight + "px";
        _this.syTimer = setInterval(function () {
          _this.mapTop -= 1;
          if (_this.mapTop <= -_this.resetHeight) {
            _this.mapTop = 0;
          }
          _this.$refs.rolUll.style.marginTop = _this.mapTop + "px";
        }, _this.num);
      }
    },
    onStop() {
      clearInterval(this.syTimer);
    },
    onStart() {
      let _this = this;
      clearInterval(this.syTimer);
      _this.syTimer = setInterval(function () {
        _this.mapTop -= 1;
        if (_this.mapTop <= -_this.resetHeight) {
          _this.mapTop = 0;
        }
        _this.$refs.rolUll.style.marginTop = _this.mapTop + "px";
      }, _this.num);
    },
    toPage(item) {
      sessionStorage.setItem("classification", "rightNotice_0");
      sessionStorage.setItem("articleId", item.id);
      this.$router.push({ path: "/third" });
      // this.$router.push({ path: "/notice", query: { id: id, type: 0 } });
    },
  },
};
</script>
<style lang="scss">
.roll-notice {
  .title {
    width: 335px;
    background: #c01515;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 28px;
    padding: 16px 0 16px 30px;
  }
  .content {
    height: 253px;
    width: 365px;
    background: #f9f9f9;
    border-radius: 1px;
    overflow: hidden;
    ul {
      padding: 16px 30px;
      li {
        padding-bottom: 23px;
        a {
          width: 216px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 216px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #222222;
          line-height: 17px;
          // margin-right: 41px;
          display: inline-block;
          &:hover {
            color: #c01515;
          }
        }
        .time {
          float: right;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #888888;
          line-height: 20px;
        }
      }
    }
  }
}
</style>